import TonWeb from "tonweb"
import { ADM_DIGITALS, API, OWNER_ADDRESS, USDT_DIGITALS } from "./vars";
import Decimal from "decimal.js";
import {Address, beginCell, toNano} from '@ton/ton'


export async function getPrice(): Promise<number|undefined> {
    const url = API + '/exchanger/ton-price'
    const response = await fetch(url)
    const price = await response.json()
    return price
}

export async function getCounterData(walletAddress: string): Promise<{counter: string, status: boolean, finish_time: Date}> {
    const url = API + '/counter/data'
    const data = {
        wallet_address: walletAddress
    }
    const response = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    const counterData = await response.json()
    return counterData
}

export async function startCounter(walletAddress: string): Promise<string> {
    const url = API + '/counter/start'
    const data = {
        wallet_address: walletAddress
    }
    const response = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    const counterData = await response.json()
    return counterData
}



export async function getCertificates(walletAddress: string, certificateAddress: string): Promise<number> {
    const url = API + '/exchanger/certificates-wallet-balance'
    const data = {
        walletAddress,
        certificateAddress
    }
    const response = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    const amount = await response.json()
    return amount
}


export async function getAllBalances(walletAddress: string, currency: string = 'all'): Promise<{ tonBalance: number|undefined, admBalance: number|undefined, usdtBalance: number|undefined }> {
    const url = API + '/exchanger/balances'
    const data = {
        walletAddress,
        currency
    }
    const response = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    const balances = await response.json()
    const res = {
        tonBalance: undefined,
        admBalance: undefined,
        usdtBalance: undefined
    }
    if(balances && balances.tonBalance) res.tonBalance = balances.tonBalance
    if(balances && balances.admBalance) res.admBalance = balances.admBalance
    if(balances && balances.usdtBalance) res.usdtBalance = balances.usdtBalance
    return res
}

export async function sendContact(contact: string): Promise<boolean> {
    const url = API + '/bot/message/send'
    const data = {
        contact: contact
    }
    const response = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    console.log(response)
    return true
}



export async function getTransaction(boc: string) {
    const bocCell = TonWeb.boc.Cell.oneFromBoc(TonWeb.utils.base64ToBytes(boc))
    const hash = TonWeb.utils.bytesToBase64(await bocCell.hash())
    return hash
}

export async function admSendTransactionData(walletAddress: string, jettonAmount: string) {
    const url = API + '/exchanger/jetton-wallet-address'
    const data = {
        walletAddress,
        jetton_address: 'adm'
    }
    const response = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    const stringJettonWalletAddress = await response.json()
    const jettonWalletAddress = new TonWeb.utils.Address(stringJettonWalletAddress)
    const digitals = (10 ** ADM_DIGITALS).toString()
    
    const responseAddress = Address.parseRaw(new TonWeb.utils.Address(walletAddress).toString(false))
    const destinationAddress = Address.parseRaw(new TonWeb.utils.Address(OWNER_ADDRESS).toString(false))
    const amount = BigInt(new Decimal(digitals).times(new Decimal(jettonAmount)).toString())

    const payload = beginCell()
        .storeUint(0xf8a7ea5, 32)                 // jetton transfer op code
        .storeUint(0, 64)                         // query_id:uint64
        .storeCoins(amount)                      // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default)
        .storeAddress(destinationAddress)         // destination:MsgAddress
        .storeAddress(responseAddress)          // response_destination:MsgAddress
        .storeUint(0, 1)                          // custom_payload:(Maybe ^Cell)
        .storeCoins(toNano(0.05))                 // forward_ton_amount:(VarUInteger 16)
        .storeUint(0,1)                           // forward_payload:(Either Cell ^Cell)
        .endCell();

    return {
        jettonWalletAddress,
        payload
    }
}


export async function usdtSendTransactionData(walletAddress: string, jettonAmount: string) {
    const url = API + '/exchanger/jetton-wallet-address'
    const data = {
        walletAddress,
        jetton_address: 'usdt'
    }
    const response = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    const stringJettonWalletAddress = await response.json()
    const jettonWalletAddress = new TonWeb.utils.Address(stringJettonWalletAddress)
    const digitals = (10 ** USDT_DIGITALS).toString()
    
    const responseAddress = Address.parseRaw(new TonWeb.utils.Address(walletAddress).toString(false))
    const destinationAddress = Address.parseRaw(new TonWeb.utils.Address(OWNER_ADDRESS).toString(false))
    const amount = BigInt(new Decimal(digitals).times(new Decimal(jettonAmount)).toString())
    const payload = beginCell()
        .storeUint(0xf8a7ea5, 32)                 // jetton transfer op code
        .storeUint(0, 64)                         // query_id:uint64
        .storeCoins(amount)                      // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default)
        .storeAddress(destinationAddress)         // destination:MsgAddress
        .storeAddress(responseAddress)          // response_destination:MsgAddress
        .storeUint(0, 1)                          // custom_payload:(Maybe ^Cell)
        .storeCoins(toNano(0.05))                 // forward_ton_amount:(VarUInteger 16)
        .storeUint(0,1)                           // forward_payload:(Either Cell ^Cell)
        .endCell();

    return {
        jettonWalletAddress,
        payload
    }
}



export async function sendAssets(address: string, type: number, amount: number = 1, tnxHash: string): Promise<boolean> {
    let res = false 
    const url = API + "/exchanger/exchange"
    const data = {
        type: type,
        amount: amount,
        address: address,
        tnxHash: tnxHash
    }
    const sending = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    if(sending.status === 200) {
        const response = await sending.json()
        res = response
    }
    console.log("SENDING: ", res)
    return res
}



