import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, SwipeableDrawer, Tooltip,  } from '@mui/material/'
import {  SWAP_CURRENCIES_NAMES, SWAP_CURRECIES_LOGO, ADM_PRICE, SWAP_DIRECTIONS_DIRECT, SWAP_DIRECTIONS_REVERS, OWNER_ADDRESS, ADM_DIGITALS } from '../../lib/vars'
import { admSendTransactionData, getAllBalances, getCounterData, getPrice, getTransaction, sendAssets, sendContact, startCounter, usdtSendTransactionData } from '../../lib/requests'
import { useTonAddress, useTonConnectModal, useTonConnectUI } from '@tonconnect/ui-react'
import Decimal from 'decimal.js'
import { useNavigate } from 'react-router-dom'

declare global {
  interface Window {
    Telegram:any;
  }
}

export function Mining() {

      const for1month = 0.00001
      const for1Sec = for1month / (30 * 24 * 60 * 60)
      
      const { state, open, close } = useTonConnectModal();
      const walletAddress = useTonAddress()
      const [tonConnectUI, setOptions] = useTonConnectUI()
      
      
      const isMobile = window.matchMedia("only screen and (max-width: 767px)").matches


      const [counter, setCounter] = useState<string>("0.00000000000")
      const [counterStatus, setCounterStatus] = useState<boolean>(false)

      const [isTimeout, setIsTimeout] = useState<boolean>(false)
      const [finishTime, setFinishTime] = useState<any>()
      const [timerId, setTimerID] = useState<any>(0);
      const [tick, setTick] = useState<boolean>(false)

      useEffect(() => {
        const now: any = new Date()
        const finished = new Date(finishTime)
        
        if((now < finished) && counterStatus) {
          const res = Number(counter) +  for1Sec
          setCounter(res.toFixed(18))
        }
        console.log("CCCC: ", counter)
        
      }, [tick, finishTime])
  
      useEffect(() => {
        if (isTimeout) {
          clearInterval(timerId)
        };
      }, [isTimeout, timerId])
  
      useEffect(() => {
        const timerID = setInterval(() => {
          setTick(!tick);
        }, 1000);
        
        return () => clearInterval(timerID);
      }, [tick])
      

      const [language, setLanguage] = useState<string>("ru")

      let tg = window.Telegram.WebApp;

      const navigate = useNavigate();

      function toMainPageClick() {
        navigate("/");
      }

      const onSetLanguage = () => {
        if(language === "ar") {
          setLanguage("ru")
        }
        if(language === "ru") {
          setLanguage("ar")
        }
      }

      useEffect(() => { 
              if(walletAddress && typeof walletAddress === 'string' && walletAddress !== "") {
                getCounterData(walletAddress)
                              .then((data) => {
                                setCounter(data.counter)
                                setCounterStatus(data.status)
                                setFinishTime(data.finish_time)
                                console.log(data.finish_time)
                              })
                              .catch((err) => console.log(err))
              }     
                  
            }, [walletAddress])



      const onConnect = () => {
        if(!walletAddress) {
          open()
        }
        
      }

      const onStartCounter = async() => {
        if(walletAddress && !counterStatus) {
          console.log(walletAddress)
          const data = await startCounter(walletAddress)
          console.log(data)
          if(data) {
            setCounter(data)
            setCounterStatus(true)
            const hours = 8
            const in8Hours = new Date()
            in8Hours.setTime(in8Hours.getTime() + (hours * 60 * 60 * 1000))
            setFinishTime(in8Hours)
          }
        }
      }
      




      return (
        <div className='mining'>
          {/*<div id='exchanger' className='title-main'>Обменник</div>*/}
          <div className='exchanger-main-section1'>           
            <div className="exchanger-direction">              
              <div className='counter'>
                <div className='avatar'>
                  <img
                    className='img'
                    alt=''
                    src='./images/bitcoin.png'
                  />                  
                </div>
                <div className='score'>{counter}</div>
                <div className='currency'>BTC</div>
              </div>
              <div className='title-bitcoin'>Бесплатно добывать Bitcoin</div>
              <div className='new-mining'>
                <div className='tm1'>
                  <div className='fix1'>
                    <img
                      className='img'
                      alt=''
                      src='./images/25-1.png'
                    /> 
                  </div>
                  <div className='fix2'>
                    <img
                        className='img'
                        alt=''
                        src='./images/25-2.png'
                      /> 
                  </div>
                </div>
                <div className='text1'><div className='trt'>32.54</div><div className='ert'>Gh/s</div></div>
                <div className='text2'>ваши начисления за сутки</div>
                {walletAddress ?
                  <div className={!counterStatus? 'btn': 'btn passive'} onClick={() => onStartCounter()}><div className='ton-icon'>{TON_ICON}</div><div className='connect'>Начать</div></div>
                  :
                  <div className='btn' onClick={onConnect}><div className='ton-icon'>{TON_ICON}</div><div className='connect'>Connect</div></div>
                }
              </div>
            </div>
            
            
            
          </div>          
        </div>
      );
}






const TON_ICON = <svg>
    <path
    d="M12.78 0H3.22C1.46 0 .346 1.896 1.231 3.43l5.9 10.227c.385.667 1.35.667 1.735 0L14.77 3.429C15.652 1.9 14.538 0 12.781 0ZM7.127 10.59 5.842 8.103l-3.1-5.546a.542.542 0 0 1 .476-.81h3.908v8.844l.001-.001Zm6.128-8.034-3.1 5.548-1.284 2.486V1.746h3.908c.428 0 .68.455.476.81Z"
    viewBox='0 0 16px 15px'
    fill="#fff"
    width="14" height="14"
    preserveAspectRatio="none"
    className='.svg'
    />
    </svg>

const ARROW_ICON = <svg>
<path
d="M8.026 0.734 c 0.231 0 0.419 0.07 0.562 0.213 a 0.734 0.734 0 0 1 0.223 0.556 v 9.893 l -0.09 2.424 l -0.488 -0.205 l 2.712 -2.964 l 1.778 -1.728 a 0.657 0.657 0 0 1 0.248 -0.156 a 0.803 0.803 0 0 1 0.306 -0.057 a 0.733 0.733 0 0 1 0.753 0.745 a 0.791 0.791 0 0 1 -0.248 0.565 l -5.17 5.126 a 0.743 0.743 0 0 1 -0.272 0.189 a 0.771 0.771 0 0 1 -0.62 0 a 0.743 0.743 0 0 1 -0.273 -0.189 L 2.27 10.02 a 0.804 0.804 0 0 1 -0.24 -0.565 c 0 -0.213 0.069 -0.39 0.207 -0.532 a 0.743 0.743 0 0 1 0.545 -0.213 c 0.11 0 0.213 0.019 0.306 0.057 a 0.657 0.657 0 0 1 0.249 0.156 l 1.778 1.728 l 2.696 2.964 l -0.472 0.205 l -0.09 -2.424 V 1.503 a 0.75 0.75 0 0 1 0.214 -0.556 a 0.765 0.765 0 0 1 0.563 -0.213 Z"
viewBox='0 0 16 16'
fill="#9043c3"
/>
</svg>


const TICK_ICON = <svg className='tick-icon'>
  <path stroke="currentColor" d="m4 6 4 4 4-4"></path>
</svg>


function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
