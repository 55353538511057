import React, { useEffect, useState } from 'react'
import { useTonAddress } from '@tonconnect/ui-react'
import { Exchanger } from '../Exchanger/Exchanger';
import { Mining } from '../Mining/Mining';


export function MainComponent() {
      const [state, SetState] = useState<number>(1)

      const onSet = (x: number) => {
            console.log("HHHH: ", x)
            SetState(x)
      }
  return (
        <div className='main-section'> 
            {state === 1 && <Mining /> }             
            {state === 2 && <Exchanger /> }
            <div className='main-menu'>
                  <div className='menu-el' onClick={() => onSet(1)}>
                        <div className='menu-img'>
                              <img
                              className='img'
                              alt=''
                              src='./images/menu_mining.png'
                              />                  
                        </div>
                        <div className='menu-text'>
                              Mining
                        </div>
                  </div>
                  <div className='menu-el tt' onClick={() => onSet(2)}>
                        <div className='menu-img'>
                              <img
                              className='img'
                              alt=''
                              src='./images/menu_market.png'
                              />                  
                        </div>
                        <div className='menu-text'>
                              Market
                        </div>
                  </div>
            </div>
        </div>
      );
  }